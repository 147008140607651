/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import ReactDOM from "react-dom";

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();

  /*const answer = window.confirm(
    `A new version of the website has been published.`
  );
  if (answer === true) {
  }*/
};

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}
